<template>
    <PermissionDialog :is-open="isPermissionDialogOpen" @cancel="isPermissionDialogOpen = false" @click="isPermissionDialogOpen = false" />
    <HomeSectionContainer :title="title">
        <template #subtitle>
            <slot name="subtitle">
                <p class="font-semibold tracking-[-0.64px] text-[#8C8C8C]">Private to you <i class="bi bi-lock"></i></p>
            </slot>
        </template>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-3 pb-3">
            <ProfileQuestionAnswer
                v-for="(question, index) in visibleQuestions"
                ref="profileQuestionAnswer"
                :key="question.key"
                :class="{ 'md:col-span-2': index === 0 && isProfileQuestions }"
                :is-editing="isProfileQuestions"
                :is-disabled="isDisabled(question.key)"
                :question="question"
                :recorder="recorder"
                @permission-open="isPermissionDialogOpen = true"
                @permission-close="isPermissionDialogOpen = false"
                @record-start="onRecordStart"
                @record-end="onRecordEnd">
                <template #icon>
                    <div
                        class="rounded-full bg-white min-w-10 h-10 border-2 border-[#E8E8E8] grid place-content-center font-semibold"
                        :class="[isProfileQuestions ? 'bg-white' : 'bg-gradient-to-l from-[#F9F6F1] to-[#E8E9FD]']">
                        <template v-if="isProfileQuestions">
                            {{ index + 1 }}
                        </template>
                        <i v-else class="bi bi-check-lg text-[#555BA2] icon-bold"></i>
                    </div>
                </template>
            </ProfileQuestionAnswer>
        </div>
        <BaseCollapsible v-if="!!restOfQuestions.length" v-model:open="isRestOfQuestionsOpen" class="flex flex-col-reverse">
            <template #content>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-3 pb-3">
                    <ProfileQuestionAnswer
                        v-for="(question, index) in restOfQuestions"
                        ref="profileQuestionAnswer"
                        :key="question.key"
                        :is-editing="isProfileQuestions"
                        :is-disabled="isDisabled(question.key)"
                        :question="question"
                        :recorder="recorder"
                        @permission-open="isPermissionDialogOpen = true"
                        @permission-close="isPermissionDialogOpen = false"
                        @record-start="onRecordStart"
                        @record-end="onRecordEnd">
                        <template #icon>
                            <div
                                class="rounded-full bg-white min-w-10 h-10 border-2 border-[#E8E8E8] grid place-content-center font-semibold"
                                :class="[isProfileQuestions ? 'bg-white' : 'bg-gradient-to-l from-[#F9F6F1] to-[#E8E9FD]']">
                                <template v-if="isProfileQuestions">
                                    {{ index + 4 }}
                                </template>
                                <i v-else class="bi bi-check-lg text-[#555BA2] icon-bold"></i>
                            </div>
                        </template>
                    </ProfileQuestionAnswer>
                </div>
            </template>
            <template #trigger>
                <p class="font-semibold tracking-[-0.64px] text-[#555BA2] pt-3">
                    {{ isRestOfQuestionsOpen ? "View less" : `View ${restOfQuestions.length} more` }}
                    <i class="bi text-sm icon-bold" :class="[isRestOfQuestionsOpen ? 'bi-chevron-up' : 'bi-chevron-down']"></i>
                </p>
            </template>
        </BaseCollapsible>
    </HomeSectionContainer>
</template>

<script setup>
import { AudioRecorder } from "/js/AudioRecorder";
import HomeSectionContainer from "~vue/ChatHome/HomeSectionContainer.vue";
import BaseCollapsible from "~vue/components/BaseCollapsible.vue";
import PermissionDialog from "~vue/Onboarding/PermissionDialog.vue";
import { computed, ref, useTemplateRef } from "vue";

import ProfileQuestionAnswer from "./ProfileQuestionAnswer.vue";

const { isProfileQuestions, userProfileQuestions } = defineProps({
    isProfileQuestions: { type: Boolean, default: true }, // prop controls whether it is used in the answer questions section, or the view/edit answers section
    userProfileQuestions: { type: Array, required: true },
    title: { type: String, required: true },
});

const visibleQuestionCount = computed(() => (isProfileQuestions ? 3 : 4));
const visibleQuestions = computed(() => userProfileQuestions.slice(0, visibleQuestionCount.value));
const restOfQuestions = computed(() => userProfileQuestions.slice(visibleQuestionCount.value));

const isRestOfQuestionsOpen = ref(false);

const isPermissionDialogOpen = ref(false);

// question key for the currently recording question, used to disable all other questions from recording
const recordingKey = ref(null);

const onRecordStart = ({ questionKey }) => {
    recordingKey.value = questionKey;
};

const onRecordEnd = () => {
    recordingKey.value = null;
};

const isDisabled = (questionKey) => recordingKey.value !== null && recordingKey.value !== questionKey;

const recorder = new AudioRecorder({
    levelCheckIntervalMs: 200,
    maxRecordingTimeSecs: 60 * 60,
});

const expand = () => {
    isRestOfQuestionsOpen.value = true;
};

const profileQuestionAnswer = useTemplateRef("profileQuestionAnswer");

const scrollToQuestion = (index) => {
    const questionElement = profileQuestionAnswer.value[index]?.$el;
    questionElement?.scrollIntoView({ behavior: "smooth", block: "center" });
};

defineExpose({ expand, scrollToQuestion });
</script>

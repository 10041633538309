<template>
    <div class="border-[#E8E8E8] p-6 rounded-xl flex flex-col justify-between" :class="[isEditingMode ? 'border-dashed border-4 bg-[#F5F5F5]' : 'border-2 bg-white']">
        <div class="mb-3 md:mb-6 flex gap-3 md:gap-6 items-start">
            <slot name="icon"></slot>
            <h3 class="heading-3 grow">{{ questionText }}</h3>
            <BaseTooltip v-if="evidenceMessages.length > 0">
                <template #trigger>
                    <div class="rounded-full min-w-10 h-10 border-2 border-[#E8E8E8] hidden md:grid place-content-center bg-white">
                        <HelpBubble />
                    </div>
                </template>
                <template #content>{{ tooltipText }}</template>
            </BaseTooltip>
        </div>
        <div class="rounded-xl px-6 py-3" :class="[isEditingMode ? 'bg-white border-2 border-[#E8E8E8]' : 'bg-[#F5F5F5]']">
            <BaseTextarea v-model:input="input" class="w-full my-2" :placeholder="placeholder" :disabled="!isEditingMode" @keydown.enter="handleSave" />
            <div class="flex gap-4 justify-between items-center">
                <BaseTooltip v-if="evidenceMessages.length > 0">
                    <template #trigger>
                        <div class="rounded-full min-w-10 h-10 border-2 border-[#E8E8E8] grid md:hidden place-content-center bg-white">
                            <HelpBubble />
                        </div>
                    </template>
                    <template #content>{{ tooltipText }}</template>
                </BaseTooltip>
                <div v-if="!!error" class="text-red-500 text-xs md:text-base font-medium tracking-[-0.64px]">{{ error }}</div>
                <div class="flex gap-3 ml-auto items-center">
                    <LoadingSpinner v-if="isCreateFetching || isUpdateFetching || isDeleteFetching" class="w-6 h-6" />
                    <template v-else-if="isEditingMode">
                        <button
                            v-if="!isTranscribing"
                            :disabled="isDisabled"
                            type="button"
                            class="bi text-2xl text-[#8C8C8C] disabled:text-[#E8E8E8]"
                            :class="[isRecording ? 'bi-stop-circle text-red-500' : 'bi-mic']"
                            @click="handleRecord"></button>
                        <LoadingSpinner v-else class="w-6 h-6" />
                        <button
                            type="button"
                            :disabled="!input || isDisabled"
                            class="px-3 py-2 rounded-xl tracking-[-0.64px] font-semibold text-[#555BA2] bg-[#555BA2]/25 disabled:bg-[#E8E8E8] disabled:text-[#8C8C8C]"
                            @click="handleSave">
                            Save
                        </button>
                    </template>
                    <template v-else>
                        <button
                            :disabled="isDisabled"
                            type="button"
                            class="p-2 w-8 h-8 rounded-full icon-bold grid place-content-center text-[#8C8C8C] disabled:text-[#E8E8E8]"
                            title="Edit"
                            @click="handleEdit">
                            <i class="bi bi-pen text-xl/none"></i>
                        </button>
                        <button
                            :disabled="isDisabled"
                            type="button"
                            class="p-2 w-8 h-8 rounded-full icon-bold grid place-content-center text-[#8C8C8C] disabled:text-[#E8E8E8]"
                            title="Delete"
                            @click="deleteExecute">
                            <i class="bi bi-trash text-xl/none"></i>
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { encodeAudio, transcribeRecording } from "/js/transcription.js";
import { router } from "@inertiajs/vue3";
import { useFetch } from "~vue/api";
import BaseTooltip from "~vue/components/BaseTooltip.vue";
import BaseTextarea from "~vue/components/form/BaseTextarea.vue";
import HelpBubble from "~vue/icons/HelpBubble.vue";
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import { logError } from "~vue/utils/logUtils";
import { computed, inject, ref, watch } from "vue";

const { $sendEvent } = inject("globalProperties");

const { question, recorder, isEditing } = defineProps({
    question: { type: Object, required: true },
    placeholder: { type: String, default: "Share your thoughts — just start typing or record a quick voice note" },
    isEditing: { type: Boolean, default: false }, // used to set initial state of editing mode
    isDisabled: { type: Boolean, default: false },
    recorder: { type: Object, required: true },
});
const emit = defineEmits(["edit", "permission-open", "permission-close", "record-start", "record-end"]);

const isEditingMode = ref(isEditing);

const questionText = computed(() => (isEditing ? question.question : (question.heading ?? question.key_display)));
const evidenceMessages = computed(() => question.evidence_messages.map(({ message }) => message).join(", "));
const tooltipText = computed(() => `Learned from: ${evidenceMessages.value}`);

const input = ref(question.answer);
const error = ref(null);

watch(input, () => {
    if (error.value) {
        error.value = null;
    }
});

const isRecording = ref(false);
const isTranscribing = ref(false);

watch(isRecording, (newValue) => {
    if (!newValue) {
        emit("record-end");
    }
});

const handleRecord = async () => {
    error.value = null;
    if (isRecording.value) {
        isRecording.value = false;
        recorder.stop();
    } else {
        try {
            if (recorder.isRecording) {
                return;
            }
            isRecording.value = true;
            recorder.on("finish", handleTranscribe);
            await recorder.start(async (micPermissionState) => {
                if (micPermissionState === "prompt") {
                    emit("permission-open");
                }
            });
            emit("record-start", { questionKey: question.key });
        } catch (e) {
            logError(`Failed to start recording: ${e}`);
            recorder.off("finish", handleTranscribe);
            isRecording.value = false;
            if (e.name === "NotAllowedError") {
                error.value = "Please allow microphone access to record your answer.";
            } else {
                error.value = "Failed to start recording. Please try again.";
            }
        } finally {
            emit("permission-close");
        }
    }
};

const onError = (e) => {
    logError(`Failed to save answer: ${e}`);
    error.value = "Failed to save answer. Please try again.";
};
const createPayload = ref({});
const {
    onFetchError: onCreateError,
    onFetchResponse: onCreateResponse,
    execute: createExecute,
    isFetching: isCreateFetching,
} = useFetch("/accounts/profile-data/", { immediate: false }).post(createPayload);
onCreateError((e) => {
    onError(e);
});
onCreateResponse(() => {
    router.reload({ only: ["user_profile_entries"] });
});

const updatePayload = ref({});
const {
    onFetchError: onUpdateError,
    onFetchResponse: onUpdateResponse,
    execute: updateExecute,
    isFetching: isUpdateFetching,
} = useFetch(`/accounts/profile-data/${+question.id}`, { immediate: false }).put(updatePayload);
onUpdateError((e) => {
    onError(e);
});
onUpdateResponse(() => {
    router.reload({ only: ["user_profile_entries"] });
    if (!isEditing) {
        isEditingMode.value = false;
    }
});

const {
    onFetchError: onDeleteError,
    onFetchResponse: onDeleteResponse,
    execute: deleteExecute,
    isFetching: isDeleteFetching,
} = useFetch(`/accounts/profile-data/${+question.id}`, { immediate: false }).delete();
onDeleteError((e) => {
    onError(e);
});
onDeleteResponse(() => {
    router.reload({ only: ["user_profile_entries"] });
});

const handleSave = (e) => {
    if (!e.shiftKey) {
        e.preventDefault();
        if (isEditingMode.value && !!question.id) {
            updatePayload.value = { value: input.value, profile_question_key: question.key_display };
            updateExecute();
        } else {
            createPayload.value = { profile_question_key: question.key_display, value: input.value };
            createExecute();
        }
    }
};

const handleEdit = () => {
    isEditingMode.value = true;
};

const handleTranscribe = async ({ blob }) => {
    isTranscribing.value = true;
    error.value = null;
    try {
        const encodedAudio = await encodeAudio(blob);
        const transcript = await transcribeRecording({
            encodedAudio,
            sendEvent: $sendEvent,
            onStart: () => {
                isTranscribing.value = true;
            },
            onError: () => {
                logError("Transcription request failed");
            },
        });
        isTranscribing.value = false;
        input.value = transcript;
    } catch (e) {
        logError(`Transcription request failed: ${e}`);
        isTranscribing.value = false;
        error.value = "Transcription failed. Please try again.";
    } finally {
        recorder.off("finish", handleTranscribe);
        isRecording.value = false;
    }
};
</script>

<template>
    <div class="flex items-center gap-2 justify-between">
        <label :for="id" :class="{ 'opacity-50': disabled }">{{ label }}</label>
        <SwitchRoot
            :id="id"
            :disabled="disabled"
            :model-value="value"
            class="min-w-[50px] h-[30px] flex data-[state=unchecked]:bg-[#8C8C8C]/75 hover:data-[state=unchecked]:bg-[#8C8C8C]/90 hover:data-[state=checked]:bg-[#2cb450] data-[state=checked]:bg-[#31C859] rounded-full relative transition-all data-[disabled]:bg-[#E8E8E8]"
            @update:model-value="($event) => (value = $event)">
            <SwitchThumb
                class="min-w-[24px] h-[24px] my-auto bg-white text-xs flex items-center justify-center shadow-xl rounded-full transition-transform translate-x-[3px] will-change-transform data-[state=checked]:translate-x-[calc(100%-1px)]" />
        </SwitchRoot>
    </div>
</template>

<script setup>
import { SwitchRoot, SwitchThumb } from "reka-ui";

defineEmits(["update:modelValue"]);
const value = defineModel({ type: Boolean, required: true });
defineProps({
    id: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        required: true,
    },
});
</script>

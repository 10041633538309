<template>
    <div>
        <p class="text-base font-semibold tracking-tight text-[#8C8C8C]">{{ headerMessage }}</p>
        <div class="flex gap-4 items-center">
            <h3 class="text-2xl font-semibold tracking-tight">{{ progressMessage }}</h3>
            <div class="rounded-full w-20 h-2 bg-[#D2D4E5] relative">
                <div class="rounded-full h-2 bg-gradient-to-br from-[#003FDC] to-[#FF2891] absolute" :style="{ width: `${percentComplete}%` }"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
const { percentComplete } = defineProps({
    percentComplete: { type: Number, default: 0 },
});

const headerMessage = computed(() => {
    if (percentComplete === 0) {
        return "Get started";
    }
    return "Your profile";
});
const progressMessage = computed(() => {
    if (percentComplete === 0) {
        return "Start personalizing Nadia";
    }
    return "Help Nadia learn about";
});
</script>

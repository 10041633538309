<template>
    <HomeSectionContainer title="Communicating with Nadia" class="px-5 md:px-0">
        <div class="grid grid-cols-4 md:gap-3 items-stretch">
            <AccountSetting class="md:col-span-1 col-span-4" :error="errors[FIELDS.LANGUAGE]">
                <template #label><label :for="idForField(FIELDS.LANGUAGE)">Language</label></template>
                <template #content>
                    <AccountSettingSelect
                        :id="idForField(FIELDS.LANGUAGE)"
                        :choices="languageChoices"
                        :model-value="preferenceValues[FIELDS.LANGUAGE] || ''"
                        @update:model-value="(value) => setPreference(FIELDS.LANGUAGE, value)" />
                </template>
            </AccountSetting>
            <AccountSetting class="md:col-span-1 col-span-4" :error="errors.choice">
                <template #label><label :for="idForField(FIELDS.COOKIE_CONSENT_CHOICE)">Cookies</label></template>
                <template #content>
                    <AccountSettingSelect
                        :id="idForField(FIELDS.COOKIE_CONSENT_CHOICE)"
                        :choices="cookieConsentChoices"
                        :model-value="preferenceValues[FIELDS.COOKIE_CONSENT_CHOICE] || ''"
                        @update:model-value="(value) => setPreference(FIELDS.COOKIE_CONSENT_CHOICE, value)" />
                </template>
            </AccountSetting>
            <AccountSetting class="md:col-span-1 col-span-4" :error="errors[FIELDS.CHECK_INS]">
                <template #label>Check-ins</template>
                <template #content>
                    <AccountSettingToggle
                        :id="idForField(FIELDS.CHECK_INS)"
                        label="Weekly check-ins"
                        :model-value="preferenceValues[FIELDS.CHECK_INS]"
                        @update:model-value="(value) => setPreference(FIELDS.CHECK_INS, value)"></AccountSettingToggle>
                </template>
            </AccountSetting>
            <AccountSetting class="md:col-span-1 col-span-4" :error="errors[FIELDS.VOICE_ENABLED]">
                <template #label>Voice</template>
                <template #content>
                    <AccountSettingToggle
                        :id="idForField(FIELDS.VOICE_ENABLED)"
                        label="Default to voice?"
                        :model-value="preferenceValues[FIELDS.VOICE_ENABLED]"
                        @update:model-value="(value) => setPreference(FIELDS.VOICE_ENABLED, value)"></AccountSettingToggle>
                </template>
            </AccountSetting>
        </div>
    </HomeSectionContainer>
</template>

<script>
export const ERROR_BAG = "account_settings";
</script>

<script setup>
import { getCookie } from "/js/utils.js";
import { router } from "@inertiajs/vue3";
import HomeSectionContainer from "~vue/ChatHome/HomeSectionContainer.vue";
import AccountSetting from "~vue/UserProfile/AccountSetting.vue";
import AccountSettingSelect from "~vue/UserProfile/AccountSettingSelect.vue";
import AccountSettingToggle from "~vue/UserProfile/AccountSettingToggle.vue";
import { toRef } from "vue";

const FIELDS = {
    LANGUAGE: "default_language",
    CHECK_INS: "prefers_weekly_check_in",
    VOICE_ENABLED: "prefers_default_to_voice",
    COOKIE_CONSENT_CHOICE: "cookie_consent_choice",
};

const USER_PROFILE_ENDPOINT = "/accounts/user-profile";
const PROFILE_ENDPOINT = "/accounts/profile/";
const COOKIE_PREFERENCE_ENDPOINT = "/accounts/cookie-preferences/";

const props = defineProps({
    userProfile: { type: Object, required: true },
    languageChoices: { type: Array, required: true },
    cookieConsentChoices: { type: Array, required: true },
    errors: { type: Object, default: () => ({}) },
});

/*
 * toRef is used to support optimistic updates. State is mutated
 * on the client, and then hydrated with any updates to the page props
 * as caused by the `router` requests below.
 */
const preferenceValues = toRef(() => props.userProfile);

function requestContextForSetting(field, value) {
    if (field === FIELDS.COOKIE_CONSENT_CHOICE) {
        return {
            method: "put",
            path: COOKIE_PREFERENCE_ENDPOINT,
            body: {
                choice: value,
                method: "profile",
            },
        };
    }

    return { method: "post", path: PROFILE_ENDPOINT, body: { [field]: value, detail: field } };
}

function setPreference(field, value) {
    const { body, method, path } = requestContextForSetting(field, value);
    preferenceValues.value[field] = value;

    router[method](path, body, {
        errorBag: ERROR_BAG,
        headers: {
            "X-CSRFToken": getCookie("csrftoken"),
            "X-Inertia-Next": USER_PROFILE_ENDPOINT,
        },
        preserveScroll: true,
        only: ["user_profile", "errors", "user_details"],
    });
}

function idForField(field) {
    return `s_${field}`;
}
</script>

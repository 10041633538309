<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 136 136" class="bg-white rounded-full">
        <path
            :class="{ hidden: isNotStarted }"
            d="M136 68C136 105.555 105.555 136 68 136C30.4446 136 0 105.555 0 68C0 30.4446 30.4446 0 68 0C105.555 0 136 30.4446 136 68ZM7.83229 68C7.83229 101.23 34.7703 128.168 68 128.168C101.23 128.168 128.168 101.23 128.168 68C128.168 34.7703 101.23 7.83229 68 7.83229C34.7703 7.83229 7.83229 34.7703 7.83229 68Z"
            stroke="#D2D4E5"
            stroke-width="16"
            stroke-linecap="square"
            stroke-linejoin="round"
            mask="url(#path-1-inside-1_6535_201790)" />
        <circle
            cx="68"
            cy="68"
            r="64"
            pathLength="100"
            fill="transparent"
            :stroke="hasProgress ? 'url(#linearColors)' : 'transparent'"
            stroke-width="8"
            stroke-linecap="round"
            :stroke-dasharray="`${percentComplete} 100`"
            class="origin-center -rotate-90 transition-all duration-300 ease-out" />
        <circle cx="68" cy="68" :r="hasProgress ? 52 : 58" pathLength="100" fill="url(#paint0_linear_6637_102815)" />
        <text x="50%" y="50%" dy="4" fill="#555BA2" dominant-baseline="middle" text-anchor="middle" class="font-semibold text-[40px]">
            {{ content }}
        </text>
        <defs>
            <mask id="path-1-inside-1_6535_201790" fill="white">
                <path
                    d="M136 68C136 105.555 105.555 136 68 136C30.4446 136 0 105.555 0 68C0 30.4446 30.4446 0 68 0C105.555 0 136 30.4446 136 68ZM7.83229 68C7.83229 101.23 34.7703 128.168 68 128.168C101.23 128.168 128.168 101.23 128.168 68C128.168 34.7703 101.23 7.83229 68 7.83229C34.7703 7.83229 7.83229 34.7703 7.83229 68Z" />
            </mask>
            <linearGradient id="paint0_linear_6637_102815" x1="-0.00186876" y1="119.999" x2="119.999" y2="-0.00146106" gradientUnits="userSpaceOnUse">
                <stop stop-color="#E9DEFA" />
                <stop offset="1" stop-color="#FFF6EB" />
            </linearGradient>
            <linearGradient id="linearColors" gradientTransform="rotate(65)">
                <stop stop-color="#FF2891"></stop>
                <stop offset="1" stop-color="#003FDC"></stop>
            </linearGradient>
        </defs>
    </svg>
</template>

<script setup>
import { computed } from "vue";
const { percentComplete } = defineProps({
    content: { type: String, default: "" },
    percentComplete: { type: Number, default: 0 },
});
const isNotStarted = computed(() => percentComplete === 0);
const hasProgress = computed(() => percentComplete > 0);
</script>

<style scoped></style>

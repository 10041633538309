<template>
    <div class="h-full flex flex-col justify-center">
        <select :id="id" v-model="value" :disabled="disabled" class="hover:opacity-85 bg-white w-auto disabled:opacity-50 focus-visible:outline-none">
            <option v-if="!value" :selected="!value" :value="''" disabled>Select preference</option>
            <option v-for="choice in choices" :key="choice[0]" :value="choice[0]" :selected="choice[0] === value">{{ choice[1] }}</option>
        </select>
    </div>
</template>

<script setup>
const value = defineModel({ type: String, required: true, default: "" });
defineProps({
    id: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    choices: {
        type: Array,
        required: true,
        default: () => [],
    },
});
</script>

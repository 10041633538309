<template>
    <CenterBubble position="top" />
    <div class="overflow-y-auto pb-10 antialiased">
        <div class="mx-auto max-w-[1200px] flex flex-col gap-10 px-0 md:px-16 py-6">
            <div class="bg-white py-6 border-0 md:border-2 border-[#E8E8E8] rounded-xl mt-32 px-6 flex flex-col gap-10">
                <div class="flex flex-col gap-4 relative">
                    <UserProfileAvatar class="-mt-24 self-center" :content="getFirstLastInitials(user_name)" :percent-complete="completionPercent" />
                    <div class="text-center">
                        <h1 class="heading-1 tracking-[-0.96px]">{{ user_name }}</h1>
                        <p v-if="!isNotStarted" class="font-semibold tracking-[-0.64px] text-[#8C8C8C]">{{ profileMessage }}</p>
                        <div class="flex gap-2 justify-center mt-4 flex-wrap">
                            <span v-if="organizationName" class="whitespace-nowrap bg-[#F5F5F5] font-semibold tracking-[-0.64px] rounded-xl px-3 py-2">{{ organizationName }}</span>
                            <span v-if="jobTitle" class="whitespace-nowrap bg-[#F5F5F5] font-semibold tracking-[-0.64px] rounded-xl px-3 py-2 flex gap-2 items-center">
                                <i class="bi bi-briefcase text-xl"></i>{{ jobTitle }}
                            </span>
                            <span v-if="yearsOfExperience" class="whitespace-nowrap bg-[#F5F5F5] font-semibold tracking-[-0.64px] rounded-xl px-3 py-2 flex gap-2 items-center">
                                <i class="bi bi-graph-up-arrow text-xl"></i>{{ yearsOfExperience }}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="!isComplete">
                    <ProfileProgress :percent-complete="completionPercent" />
                    <div class="flex gap-2 pt-4 overflow-x-auto">
                        <button
                            v-for="(entry, index) in incompleteUserProfileEntries"
                            :key="entry.key"
                            type="button"
                            class="whitespace-nowrap flex gap-2 items-center rounded-xl border-2 border-[#E8E8E8] px-3 py-2 font-semibold tracking-[-0.64px]"
                            @click="handleAddProfileButtonClick(index)">
                            <i class="bi bi-plus-lg gradient-text icon-bold text-lg/none"></i>{{ entry.key_display }}
                        </button>
                    </div>
                </div>
                <HomeCoachingInsightsSection :user-insights="user_insights" :default-chat-url="$startChatUrl"></HomeCoachingInsightsSection>
            </div>
            <BaseTabs v-model="tabModel" class="px-6 md:px-0" :tab-ids="tabIds" hide-single-tab-list>
                <template v-for="id in tabIds" :key="id" #[`trigger-${id}`]>
                    <div class="p-3 tracking-[-0.64px]">{{ tabTitles[id] }}</div>
                </template>
                <template #[`content-${TAB_IDS.LEARN}`]>
                    <div class="pt-10">
                        <ProfileQuestions ref="profileQuestions" title="Help Nadia get to know you" :user-profile-questions="incompleteUserProfileEntries">
                            <template #subtitle>
                                <p class="font-semibold tracking-[-0.64px] text-[#8C8C8C]">The more you share, the smarter Nadia gets</p>
                            </template>
                        </ProfileQuestions>
                    </div>
                </template>
                <template #[`content-${TAB_IDS.STYLE}`]>
                    <div class="pt-10">
                        <ProfileQuestions title="How you work" :user-profile-questions="completeUserProfileEntries" :is-profile-questions="false" />
                    </div>
                </template>
            </BaseTabs>
            <HomeAccountSettingsSection
                :user-profile="user_profile"
                :language-choices="language_choices"
                :cookie-consent-choices="cookie_consent_choices"
                :errors="errors[SETTINGS_ERROR_BAG]" />
        </div>
    </div>
</template>

<script>
import LeftSidebarLayout from "~vue/layouts/LeftSidebarLayout.vue";
export default { layout: LeftSidebarLayout };
</script>

<script setup>
import HomeAccountSettingsSection, { ERROR_BAG as SETTINGS_ERROR_BAG } from "~vue/ChatHome/HomeAccountSettingsSection.vue";
import HomeCoachingInsightsSection from "~vue/ChatHome/HomeCoachingInsightsSection.vue";
import BaseTabs from "~vue/components/BaseTabs.vue";
import CenterBubble from "~vue/components/CenterBubble.vue";
import ProfileProgress from "~vue/UserProfile/ProfileProgress.vue";
import ProfileQuestions from "~vue/UserProfile/ProfileQuestions.vue";
import UserProfileAvatar from "~vue/UserProfile/UserProfileAvatar.vue";
import { getFirstLastInitials } from "~vue/utils/stringUtils";
import { computed, inject, nextTick, onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";

const TAB_IDS = {
    GROWTH: "growth",
    LEARN: "learn",
    STYLE: "style",
};

const tabTitles = {
    [TAB_IDS.GROWTH]: "Growth Areas",
    [TAB_IDS.LEARN]: "Want to learn",
    [TAB_IDS.STYLE]: "How you work",
};
const { $setEventContext } = inject("globalProperties");

const { user_profile, user_profile_entries, organization_details, event_context } = defineProps({
    user_profile: { type: Object, required: true },
    user_profile_entries: { type: Object, required: true },
    user_name: { type: String, default: "" },
    organization_details: { type: Object, required: true },
    user_insights: { type: Array, default: () => [] },
    language_choices: { type: Array, default: () => [] },
    cookie_consent_choices: { type: Array, default: () => [] },
    errors: { type: Object, default: () => ({}) },
    event_context: { type: Object, required: true },
});

const jobTitle = computed(() => user_profile.components.job_title);
const organizationName = computed(() => (organization_details.is_default ? "" : organization_details.name));
const yearsOfExperience = computed(() => {
    const yoeAnswer = user_profile_entries.find(({ key }) => key === "years_of_experience")?.answer;

    return parseInt(yoeAnswer) ? `${parseInt(yoeAnswer)} Years of Experience` : null;
});

const completionPercent = computed(() => Math.ceil((user_profile_entries.filter(({ answer }) => !!answer).length / user_profile_entries.length) * 100));
const isComplete = computed(() => completionPercent.value === 100);
const isNotStarted = computed(() => completionPercent.value === 0);
const incompleteUserProfileEntries = computed(() => user_profile_entries.filter(({ answer }) => !answer));
const completeUserProfileEntries = computed(() => user_profile_entries.filter(({ answer }) => !!answer));

const tabIds = computed(() => {
    const ids = [];
    if (incompleteUserProfileEntries.value.length > 0) {
        ids.push(TAB_IDS.LEARN);
    }
    if (completeUserProfileEntries.value.length > 0) {
        ids.push(TAB_IDS.STYLE);
    }
    return ids;
});

watch(tabIds, (newTabIds) => {
    // If the user deletes their only profile answer, make sure they get back to the first tab
    if (newTabIds.length > 0 && !newTabIds.includes(tabModel.value)) {
        tabModel.value = newTabIds[0];
    }
});

const tabModel = ref(tabIds.value[0]);

const profileQuestions = useTemplateRef("profileQuestions");

const handleAddProfileButtonClick = (index) => {
    tabModel.value = TAB_IDS.LEARN;
    nextTick(() => {
        setTimeout(() => {
            const VISIBLE_QUESTION_COUNT = 3;
            if (index > VISIBLE_QUESTION_COUNT - 1) {
                profileQuestions.value?.expand();
            }
            setTimeout(() => {
                profileQuestions.value?.scrollToQuestion(index);
            }, 200);
        }, 200);
    });
};

const profileMessage = computed(() => {
    return `Profile ${completionPercent.value}% complete`;
});

onMounted(() => {
    $setEventContext(event_context);
});
onUnmounted(() => {
    $setEventContext(event_context);
});
</script>
